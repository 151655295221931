import * as React from 'react'
import IndexLayout from '../templates/IndexLayout'
import Hero from '../components/Hero'
import Partnerships from '../components/Partnerships'
import StatsStream from '../components/StatsStream'

import heroVideoDesktop from '../videos/hero-desktop-cropped-hd.webm'
import heroVideoMobile from '../videos/hero-mobile-cropped-hd.webm'
import heroPosterDesktop from '../videos/hero-desktop-thumbnail-hd.webp'
import heroPosterMobile from '../videos/hero-mobile-thumbnail-hd.webp'

import financialVideoDesktop from '../videos/financial-desktop-cropped-hd.webm'
import financialVideoMobile from '../videos/financial-mobile-cropped-hd.webm'
import financialPosterDesktop from '../videos/financial-desktop-thumbnail-hd.webp'
import financialPosterMobile from '../videos/financial-mobile-thumbnail-hd.webp'

import sustainabilityVideoDesktop from '../videos/sustainability-desktop-cropped-hd.webm'
import sustainabilityVideoMobile from '../videos/sustainability-mobile-cropped-hd.webm'
import sustainabilityPosterDesktop from '../videos/sustainability-desktop-thumbnail-hd.webp'
import sustainabilityPosterMobile from '../videos/sustainability-mobile-thumbnail-hd.webp'

import telematicsVideoDesktop from '../videos/telematics-desktop-cropped-hd.webm'
import telematicsVideoMobile from '../videos/telematics-mobile-cropped-hd.webm'
import telematicsPosterDesktop from '../videos/telematics-desktop-thumbnail-hd.webp'
import telematicsPosterMobile from '../videos/telematics-mobile-thumbnail-hd.webp'
import VehicleBrands from '../components/VehicleBrands'
import OperatorRegisterInterestForm from '../components/OperatorRegisterInterestForm'
import { Container } from '@material-ui/core'

const IndexTemplate: React.FunctionComponent = () => {
  return (
    <IndexLayout title="Fleets with Zeti" description="We help fleets convert to EV by making it as simple, easy and transparent as paying for any other utility with our patent-pending pay-per-mile financial technology">
      <Hero
        inverse
        topHero
        videoSourceDesktop={heroVideoDesktop}
        videoSourceMobile={heroVideoMobile}
        videoPosterDesktop={heroPosterDesktop}
        videoPosterMobile={heroPosterMobile}
        mainText="Revolutionizing transport finance for good."
        bodyText={
          <>
            We help fleets convert to EVs by making it as simple, easy and transparent as paying for any other utility with our patent-pending pay-per-mile financial technology.
          </>
        }
        ctaLink="/fleet#fleet-register-interest-form"
        ctaText="Register Interest"
      />
      <VehicleBrands />
      <Hero
        videoSourceDesktop={financialVideoDesktop}
        videoSourceMobile={financialVideoMobile}
        videoPosterDesktop={financialPosterDesktop}
        videoPosterMobile={financialPosterMobile}
        mainText="Cashflow optimization"
        bodyText={
          <>
            Our patent-pending pay-per-mile financing allows you to only pay for your vehicle finance when you are actually benefitting from the vehicle. This is all calculated automatically with an included top-of-the-range telematics solution and Zeti's ZERO platform.
          </>
        }
        ctaLink="/fleet#fleet-register-interest-form"
        ctaText="Register Interest"
      />
      <Hero
        inverse
        videoSourceDesktop={telematicsVideoDesktop}
        videoSourceMobile={telematicsVideoMobile}
        videoPosterDesktop={telematicsPosterDesktop}
        videoPosterMobile={telematicsPosterMobile}
        mainText="No excess mileage fees"
        bodyText={
          <>
            We don't charge you for over-using your vehicles by setting annual mileage caps. Instead your contract is for a given quantity of miles shared over all vehicles in your fleet, with a minimum mileage set every six months. This is totally customizable, so it is suitable for very high or low mileages alike.
            < br /><br />
            This means no more shuffling vehicles onto different routes to balance their mileages!
          </>
        }
        ctaLink="/fleet#fleet-register-interest-form"
        ctaText="Register Interest"
      />
      <Hero
        videoSourceDesktop={sustainabilityVideoDesktop}
        videoSourceMobile={sustainabilityVideoMobile}
        videoPosterDesktop={sustainabilityPosterDesktop}
        videoPosterMobile={sustainabilityPosterMobile}
        mainText="We only finance clean transport"
        bodyText={
          <>
            We connect you with investors who are keen to invest in clean transport to benefit the environment, and we provide you with a live feed of those environmental benefits per vehicle.
            <br /><br />
            We don't take a discount on vehicle prices, or charge you a fee - instead we charge the investors a fee for the automation and management that we provide.
            <StatsStream />
          </>
        }
      />
      <Container maxWidth="md">
        <a id="fleet-register-interest-form" />
        <OperatorRegisterInterestForm />
      </Container>
    </IndexLayout>
  )
}

export default IndexTemplate
